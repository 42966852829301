const setDisabled = (element, disable) => {
  if (disable) {
    element.classList.add('is-disabled');
    element.setAttribute('aria-disabled', 'true');
  } else {
    element.classList.remove('is-disabled');
    element.setAttribute('aria-disabled', 'false');
  }
};

const addQueryParameters = (link) => {
  if (link === '' || link.startsWith('#') || window.location.search === '') {
    return link;
  }
  if (link.includes('?')) {
    const urlSegments = link.split('?');
    const baseUrl = urlSegments[0];
    const savedParameters = urlSegments[1];
    return `${baseUrl + window.location.search }&${ savedParameters}`;
  }
  return link + window.location.search;
};

const initializeNavigationDropdownHandler = () => {
  const optionSelected = document.querySelector('#navigation-dropdown-select');
  const submitButton = document.querySelector(
    '[data-id="navigation-dropdown-submit"]'
  );

  if (optionSelected) {
    optionSelected.addEventListener('change', () => {
      const link = addQueryParameters(optionSelected.value);
      if (submitButton) {
        submitButton.setAttribute('href', link);
        setDisabled(submitButton, link === '');
        return;
      }
      // automatically redirects if no submit button is present
      window.location.href = link;
    });
  }
};

initializeNavigationDropdownHandler();
